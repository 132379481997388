import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "w-full mx-auto",
  ref: "el"
}

import { toRefs } from "vue";

interface Props {
  numPercent: number;
  maxNumPercent: number;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'Process',
  props: {
    numPercent: {},
    maxNumPercent: {}
  },
  setup(__props: any) {

const PERCENT_NUM = {
  MAX_CONSUMPTION: 100,
  MIN_CONSUMPTION: 27,
  HALF_CONSUMPTION: 50,
};

const props = __props;
const { numPercent, maxNumPercent } = toRefs(props);

const checkFullWidth = (maxNumPercent: number, numPercent: number) => {
  return (
    (maxNumPercent > PERCENT_NUM.MAX_CONSUMPTION &&
      numPercent > PERCENT_NUM.MAX_CONSUMPTION) ||
    maxNumPercent <= PERCENT_NUM.MAX_CONSUMPTION
  );
};

const getWidthPercent = (numPercent: number) => {
  if (numPercent <= PERCENT_NUM.MIN_CONSUMPTION) {
    return PERCENT_NUM.MIN_CONSUMPTION;
  }

  if (numPercent > PERCENT_NUM.MAX_CONSUMPTION) {
    return PERCENT_NUM.MAX_CONSUMPTION;
  }

  return numPercent;
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass([
        'flex rounded-2xl',
        checkFullWidth(_unref(maxNumPercent), _unref(numPercent)) ? 'w-full' : 'w-1/2',
        _unref(numPercent) > 100 ? '' : 'bg-gray-300',
      ])
    }, [
      _createElementVNode("div", {
        class: _normalizeClass([
          'rounded-2xl bg-green-500 text-center text-white',
          _unref(numPercent) !== 0 ? 'visible' : 'invisible',
        ]),
        style: _normalizeStyle(`width: ${getWidthPercent(_unref(numPercent))}%`)
      }, _toDisplayString(_unref(numPercent).toFixed(0) + " %"), 7)
    ], 2)
  ], 512))
}
}

})